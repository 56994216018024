.logout {
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-clip: padding-box; /* !importanté */
  border: solid 2px transparent; /* !importanté */
  border-radius: 40px;
  padding: 10px 20px;
  position: relative;
  display: inline-block;
  background: linear-gradient(180deg, rgba(255,83,121,0.7) 0%, #ff5379 100%);
  margin-top: 30px;
  user-select: none;
  cursor: pointer;
}
.logout:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0ff;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(100.57deg, rgba(255,83,121,0.5), #ff5379);
}
/*# sourceMappingURL=src/components/LogoutButton.css.map */